import { togglePageDialog } from 'actions'
import { CONSENT_STORAGE_PROP, CONSENT_HEADER_TEXT, CONSENT_CONTENT_TEXT, CONSENT_DIALOG_ID } from 'appConstants'
import { Button, Dialog, OutlineButton } from 'components/ReUsable'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { integrationTestRunning } from 'utils/helpers'

const StyledContent = styled.div`
  padding: 10px;
`

const StyledText = styled.p`
  font-family: 'open-sans-regular';
  font-size: 16px;
`
type ConsentType = 'rejected' | 'agreed' | 'not-initialized' | ''

// Custom Hook for managing consent state and logic
const useConsent = (dispatch: any) => {
  const [consentVal, setConsentVal] = useState<ConsentType>('not-initialized')

  // Memoize consentStorage value to avoid recalculating it on each render
  const consentStorage = useCallback((): ConsentType => (localStorage.getItem(CONSENT_STORAGE_PROP) as ConsentType) || '', [])

  // Effect for checking initial consent status
  useEffect(() => {
    const storedConsent = consentStorage()
    if (!['agreed', 'rejected'].includes(storedConsent)) {
      dispatch(togglePageDialog({ dialogId: CONSENT_DIALOG_ID }))
    } else {
      setConsentVal(storedConsent)
    }
  }, [dispatch, consentStorage])

  useEffect(() => {
    const storedConsent = consentStorage()
    if (consentVal === 'not-initialized' && !['agreed', 'rejected'].includes(storedConsent)) {
      setConsentVal('') // Show the dialog if consent is not yet given
    }
  }, [consentVal, consentStorage])

  const handleConsentSelection = (val: ConsentType) => {
    setConsentVal(val)
    localStorage.setItem(CONSENT_STORAGE_PROP, val)
  }

  return { consentVal, handleConsentSelection }
}

const TrackingConsent = () => {
  const dispatch = useDispatch()
  const { consentVal, handleConsentSelection } = useConsent(dispatch)

  if (integrationTestRunning(process.env.REACT_APP_ENV_MODE)) {
    return null
  }

  return (
    <Dialog id="consent-dialog" className="modal-page" title={CONSENT_HEADER_TEXT} show={!consentVal} hideCloseButton={true}>
      <StyledContent>
        <StyledText>{CONSENT_CONTENT_TEXT}</StyledText>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Button onClick={() => handleConsentSelection('agreed')}>AGREE</Button>
          <OutlineButton onClick={() => handleConsentSelection('rejected')}>REJECT</OutlineButton>
        </div>
      </StyledContent>
    </Dialog>
  )
}

export default TrackingConsent
