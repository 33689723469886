// @flow

import { useReducer, useEffect, useCallback } from 'react'
import { Spinner, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { Button, OutlineButton, Tooltip, Alert, ALERT_TYPE, DatePicker, TrashButton } from 'components/ReUsable'
import { useSelector, useDispatch } from 'react-redux'
import { getLicenses, updateDeviceLicense, deleteDeviceLicense, addDeviceLicense } from 'actions'
import { checkPermissionFromAuth0 } from 'utils/helpers'
import { useAuth0 } from 'utils/react_auth0_spa'
import SelectElement from 'components/ReUsable/SelectElement'

const CenterContentDiv = styled.div`
  margin: 0 auto;
  height: 25px;
  width: 30px;
`
const ContentDiv = styled.div`
  padding: 25px 50px 25px;
  font-size: 18px;
  font-family: open-sans-regular;
`
const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
`
const StyledInfinityButton = styled.button`
  color: var(--color-primary);
  border: none;
  border-radius: 0.25rem;
  height: 100%;
  :active {
    background-color: var(--color-primary);
    & svg {
      color: var(--color-background-secondary);
    }
  }
`
const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-alert-error);
  cursor: pointer;
  font-size: 18px;
`
const StyledTableContainer = styled(TableContainer)`
  margin: 2rem 0 2rem 0;
`
const StyledTableCell = styled(TableCell)`
  font-size: 16px !important;
  font-family: open-sans-regular !important;
`
const StyledEditFields = styled.span`
  display: flex;
  justify-content: space-evenly;
  & input {
    text-align: center;
    max-width: 100px;
  }
`
const LicenseTagsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const StyledLicenseStateTags = styled(Button)`
  background-color: inherit;
  color: var(--color-primary);
  min-height: 2.5rem;
  min-width: 8rem;
  text-transform: uppercase;
  &:focus {
    box-shadow: none;
  }
  &.active {
    background-color: var(--color-primary);
    color: var(--color-lightest);
    &:hover {
      background-color: var(--color-background-primary);
      color: var(--color-primary);
    }
  }
`
const StyledRequiredSpan = styled.span`
  color: var(--color-error-text);
  position: absolute;
  font-size: 12px;
  bottom: 4rem;
`

const FlexDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

// Defining actions for state change. These are the actions that will be dispatched by the components.
const ActionTypes = {
  INITIALIZE: {
    SET: 'INITIALIZE_SET'
  },
  LICENSE_STATE: {
    TOGGLE: 'LICENSE_STATE_TOGGLE'
  },
  LICENSE_EDITING: {
    SET: 'EDITING_SET',
    DISCARD: 'EDITING_DISCARD'
  },
  CONFIRM_DELETE: {
    SET: 'CONFIRM_DELETE_SET'
  },
  LOADING: {
    SET: 'LOADING_SET'
  },
  LICENSE_PERMISSIONS: {
    SET: 'LICENSE_PERMISSIONS_SET'
  }
}

// Reducer function to manage state updates on the manage license part
const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE.SET:
      return {
        ...state,
        isEditing: { value: false, key: '' },
        editStartDate: null,
        editEndDate: null,
        confirmDelete: { value: false, key: '' },
        loading: false
      }
    case ActionTypes.LICENSE_EDITING.SET:
      return {
        ...state,
        ...action.payload
      }

    case ActionTypes.CONFIRM_DELETE.SET: {
      return {
        ...state,
        confirmDelete: { value: !(action.payload.key === ''), key: action.payload.key },
        error: null
      }
    }

    case ActionTypes.LICENSE_EDITING.DISCARD: {
      return {
        ...state,
        addingLicense: false,
        addLicenseTier: 'platinum',
        addLicenseEndDate: null,
        addLicenseStartDate: null,
        infiniteEndDate: false,
        loading: false
      }
    }
    default:
      return state
  }
}

// Selector function to extract state from Redux store
// In order for cleanup let's leave it seperate, maybe I will take it to another folder soon.
const selectDeviceState = (state) => ({
  serialNumber: state?.devices?.current?.serialNumber,
  licenses: state?.devices?.licenses,
  errors:
    state.errors.ADD_DEVICE_LICENSE?.code || state.errors.UPDATE_DEVICE_LICENSE?.code || state.errors.DELETE_DEVICE_LICENSE?.code
      ? state.errors.ADD_DEVICE_LICENSE || state.errors.UPDATE_DEVICE_LICENSE || state.errors.DELETE_DEVICE_LICENSE
      : null
})

export const ManageLicenseDialog = () => {
  const { getTokenSilently } = useAuth0()
  const dispatch = useDispatch()
  // Extracting state from Redux store
  const { serialNumber, licenses, errors } = useSelector(selectDeviceState) // eslint-disable-line @typescript-eslint/no-unused-vars
  // Using the 'dispatch' function to dispatch actions instead
  const _fetchLicenses = (obj) => dispatch(getLicenses(obj))
  const _updateLicense = (obj) => dispatch(updateDeviceLicense(obj))
  const _deleteLicense = (obj) => dispatch(deleteDeviceLicense(obj))
  const _addLicense = (obj) => dispatch(addDeviceLicense(obj))

  const initialState = {
    // Defining initial state values here
    licenseState: { active: true, followup: true, consumed: false, inclusive: true },
    isEditing: { value: false, key: '' },
    editStartDate: null,
    editEndDate: null,
    confirmDelete: { value: false, key: '' },
    addingLicense: false,
    addLicenseTier: 'platinum',
    addLicenseStartDate: null,
    addLicenseEndDate: null,
    infiniteEndDate: false,
    loading: false,
    error: null,
    licensePermisisons: { create: false, write: false }
  }

  const [state, manageLicensedispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const checkAuth0Permissions = async function () {
      const token = await getTokenSilently()
      const createLicense = await checkPermissionFromAuth0('licenses', /create/, token)
      const writeLicense = await checkPermissionFromAuth0('licenses', /write/, token)
      // setLicensePermisisons({ create: createLicense, write: writeLicense })
      manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { licensePermisisons: { create: createLicense, write: writeLicense } } })
    }
    checkAuth0Permissions()
  }, [])

  useEffect(() => {
    if (errors) {
      manageLicensedispatch({
        type: ActionTypes.LICENSE_EDITING.SET,
        payload: {
          isEditing: { value: false, key: '' },
          loading: false,
          error: errors?.message ? errors?.message : 'Something went wrong'
        }
      })
    }
  }, [errors])

  const fetchActiveLicenses = useCallback(() => {
    _fetchLicenses({ serialNumber: serialNumber, state: state.licenseState })
  }, [state.licenseState, serialNumber])

  useEffect(fetchActiveLicenses, [state.licenseState])

  const handleDiscardAdd = useCallback(() => {
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.DISCARD
    })
  }, [])

  useEffect(() => {
    manageLicensedispatch({
      type: ActionTypes.INITIALIZE.SET
    })

    handleDiscardAdd()
  }, [licenses, handleDiscardAdd])

  const handleLicenseStateChange = useCallback(
    (e) => {
      manageLicensedispatch({
        type: ActionTypes.LICENSE_EDITING.SET,
        payload: {
          licenseState: { ...state.licenseState, [e.name]: !state.licenseState[e.name] },
          isEditing: { value: false, key: '' },
          editStartDate: null,
          editEndDate: null,
          confirmDelete: { value: false, key: '' },
          loading: false
        }
      })
      handleDiscardAdd()
    },
    [state.licenseState, handleDiscardAdd]
  )

  const handleEditClick = useCallback(
    (license: any) => {
      manageLicensedispatch({
        type: ActionTypes.LICENSE_EDITING.SET,
        payload: {
          isEditing: { value: true, key: license.key },
          confirmDelete: { value: false, key: '' }
        }
      })
      handleDiscardAdd()
    },
    [handleDiscardAdd]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCancelEditClick = useCallback((obj: any) => {
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.SET,
      payload: {
        isEditing: { value: false, key: '' },
        editStartDate: null,
        editEndDate: null,
        infiniteEndDate: false
      }
    })
  }, [])

  const handleConfirmDeleteNo = useCallback(() => {
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.SET,
      payload: {
        confirmDelete: { value: false, key: '' }
      }
    })
  }, [])

  const handleConfirmDeleteYes = useCallback(
    (key: any) => {
      manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { confirmDelete: { value: true, key: key } } })
      _deleteLicense({ key: key, serialNumber: serialNumber, state: state.licenseState })
    },
    [serialNumber, state.licenseState]
  )

  const handleStartDateChange = useCallback((date?: any) => {
    manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { editStartDate: new Date(date).toISOString() } })
  }, [])

  const handleEndDateChange = useCallback((date?: any) => {
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.SET,
      payload: {
        infiniteEndDate: false,
        editEndDate: new Date(date).toISOString()
      }
    })
  }, [])

  const handleUpdateChanges = useCallback(() => {
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.SET,
      payload: {
        loading: true,
        error: null
      }
    })
    manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { error: null } })
    const license: any = licenses.find((l: any) => l.key === state.isEditing.key)
    _updateLicense({
      key: state.isEditing.key,
      details: {
        tier: license?.tier,
        start: state.editStartDate || license?.start,
        duration: license?.duration,
        end: state.infiniteEndDate ? null : state.editEndDate || license?.end,
        deviceSerialNumber: serialNumber
      },
      state: state.licenseState
    })
  }, [state.editEndDate, state.editStartDate, state.infiniteEndDate, state.isEditing.key, licenses, serialNumber, state.licenseState])

  const handleDelete = useCallback((key) => {
    manageLicensedispatch({ type: ActionTypes.CONFIRM_DELETE.SET, payload: { key: key } })
  }, [])

  const handleAddLicenseDetails = useCallback(() => {
    if (!state.addLicenseStartDate) {
      manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { error: 'Start Date cannot be empty' } })
      return
    }
    manageLicensedispatch({
      type: ActionTypes.LICENSE_EDITING.SET,
      payload: {
        loading: true,
        error: null
      }
    })
    _addLicense({
      serialNumber,
      details: {
        tier: state.addLicenseTier,
        start: state.addLicenseStartDate,
        end: state.infiniteEndDate ? null : state.addLicenseEndDate
      },
      state: state.licenseState
    })
  }, [serialNumber, state.addLicenseEndDate, state.addLicenseStartDate, state.addLicenseTier, state.infiniteEndDate, state.licenseState])

  if (!licenses) {
    return (
      <CenterContentDiv>
        <Spinner animation="border" variant="secondary" />
      </CenterContentDiv>
    )
  }

  return (
    <ContentDiv>
      {state.error ? (
        <Row>
          <Col>
            <Alert message={state.error} type={ALERT_TYPE.danger} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>Filter license state(s)</Col>
        <Col>
          <LicenseTagsContainer>
            {['active', 'followup', 'consumed', 'inclusive'].map((tag, key) => (
              <StyledLicenseStateTags key={key} name={tag} className={state.licenseState[tag] ? 'active' : ''} onClick={(e: any) => handleLicenseStateChange(e.target)}>
                {tag}
              </StyledLicenseStateTags>
            ))}
          </LicenseTagsContainer>
        </Col>
      </Row>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left">License</StyledTableCell>
              <StyledTableCell align="center">Start Date</StyledTableCell>
              <StyledTableCell align="center">End Date</StyledTableCell>
              <StyledTableCell align="left">Key</StyledTableCell>
              <StyledTableCell align="left">State</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenses.length > 0 ? (
              licenses.map((license: any, key: any) => (
                <TableRow key={key}>
                  <TableCell align="left">
                    {state.licensePermisisons.write && license.state !== 'inclusive' ? (
                      state.isEditing.value && state.isEditing.key === license.key ? (
                        <StyledPrimaryFontAwesomeIcon icon={['far', 'xmark'] as any} onClick={() => handleCancelEditClick(license)} />
                      ) : (
                        <StyledPrimaryFontAwesomeIcon icon={['fas', 'pen']} onClick={() => handleEditClick(license)} data-cy="start-edit-button" />
                      )
                    ) : null}
                  </TableCell>
                  <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                    {license.tier}
                  </TableCell>
                  <TableCell align="center">
                    {state.isEditing.value && state.isEditing.key === license.key ? (
                      <DatePicker
                        name="modifyLicenseEditStartDate"
                        selected={new Date(state.editStartDate || license.start)}
                        timeInputLabel="Time:"
                        timeFormat="HH:mm"
                        showTimeSelect={true}
                        dateFormat="dd.MM.yyyy HH:mm"
                        timeIntervals={60}
                        onChange={handleStartDateChange}
                        maxDate={state.infiniteEndDate ? undefined : state.editEndDate ? new Date(state.editEndDate) : license.end ? new Date(license.end) : undefined}
                        placeholderText="Select Start Date"
                      ></DatePicker>
                    ) : (
                      !license.start ? '-' : moment(license.start).format('DD.MM.yyyy HH:mm')
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {state.isEditing.value && state.isEditing.key === license.key ? (
                      <span style={{ display: 'flex' }}>
                        <DatePicker
                          name="modifyLicenseEditEndDate"
                          selected={state.infiniteEndDate ? undefined : state.editEndDate ? new Date(state.editEndDate) : license.end ? new Date(license.end) : undefined}
                          timeInputLabel="Time:"
                          timeFormat="HH:mm"
                          showTimeSelect={true}
                          dateFormat="dd.MM.yyyy HH:mm"
                          timeIntervals={60}
                          onChange={handleEndDateChange}
                          minDate={new Date(state.editStartDate || license.start)}
                          placeholderText="Select End Date"
                        ></DatePicker>
                        <Tooltip message="Set end date to infinte.">
                          <StyledInfinityButton>
                            <StyledPrimaryFontAwesomeIcon
                              icon={['far', 'infinity']}
                              onClick={() =>
                                manageLicensedispatch({
                                  type: ActionTypes.LICENSE_EDITING.SET,
                                  payload: {
                                    infiniteEndDate: true
                                  }
                                })
                              }
                            />
                          </StyledInfinityButton>
                        </Tooltip>
                      </span>
                    ) : license.end ? (
                      moment(license.end).format('DD.MM.yyyy HH:mm')
                    ) : license.state === 'inclusive' ? '-' : (
                      'Never'
                    )}
                  </TableCell>
                  <TableCell align="left">{license.key}</TableCell>
                  <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                    {license.state}
                  </TableCell>
                  <TableCell align="right">
                    {state.licensePermisisons.write && license.state !== 'inclusive' ? (
                      state.confirmDelete.value && state.confirmDelete.key === license.key ? (
                        <FlexDiv>
                          Delete?&nbsp;&nbsp;
                          <StyledPrimaryFontAwesomeIcon icon={['far', 'circle-check'] as any} onClick={() => handleConfirmDeleteYes(license.key)} />
                          &nbsp;&nbsp;
                          <StyledAlertFontAwesomeIcon icon={['far', 'circle-xmark'] as any} onClick={handleConfirmDeleteNo} />
                        </FlexDiv>
                      ) : (
                        <TrashButton bordered={true} onClick={() => handleDelete(license.key)} dataCy="start-delete-button"/>
                      )
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  No license found
                </TableCell>
              </TableRow>
            )}
            {state.addingLicense ? (
              <TableRow>
                <TableCell align="left" colSpan={2}>
                  <SelectElement
                    name="addLicenseTier"
                    value={state.addLicenseTier}
                    onChange={(e) =>
                      manageLicensedispatch({
                        type: ActionTypes.LICENSE_EDITING.SET,
                        payload: {
                          addLicenseTier: e.target.value
                        }
                      })
                    }
                    width="128px"
                  >
                    <option value="basic">Basic</option>
                    <option value="full">Full</option>
                    <option value="platinum">Platinum</option>
                  </SelectElement>
                </TableCell>
                <TableCell align="center">
                  <DatePicker
                    name="modifyLicenseAddStartDate"
                    selected={state.addLicenseStartDate ? new Date(state.addLicenseStartDate) : undefined}
                    timeInputLabel="Time:"
                    timeFormat="HH:mm"
                    showTimeSelect={true}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeIntervals={60}
                    onChange={(startDate: Date | null) =>
                      manageLicensedispatch({
                        type: ActionTypes.LICENSE_EDITING.SET,
                        payload: {
                          addLicenseStartDate: startDate ? startDate.toISOString() : null
                        }
                      })
                    }
                    maxDate={state.addLicenseEndDate ? new Date(state.addLicenseEndDate) : undefined}
                    placeholderText="Select Start Date"
                  >
                    <StyledEditFields>
                      <span style={{ color: 'red' }}>*</span>
                    </StyledEditFields>
                  </DatePicker>
                </TableCell>
                <TableCell align="center">
                  <span style={{ display: 'flex' }}>
                    <DatePicker
                      name="modifyLicenseAddEndDate"
                      selected={state.infiniteEndDate ? undefined : state.addLicenseEndDate ? new Date(state.addLicenseEndDate) : undefined}
                      timeInputLabel="Time:"
                      timeFormat="HH:mm"
                      showTimeSelect={true}
                      dateFormat="dd.MM.yyyy HH:mm"
                      timeIntervals={60}
                      onChange={(endDate: Date | null) => {
                        manageLicensedispatch({
                          type: ActionTypes.LICENSE_EDITING.SET,
                          payload: {
                            infiniteEndDate: false,
                            addLicenseEndDate: endDate ? endDate.toISOString() : null
                          }
                        })
                      }}
                      minDate={state.addLicenseStartDate ? new Date(state.addLicenseStartDate) : undefined}
                      placeholderText="Select End Date"
                    ></DatePicker>
                    <Tooltip message="Set end date to infinte.">
                      <StyledInfinityButton>
                        <StyledPrimaryFontAwesomeIcon
                          icon={['far', 'infinity']}
                          onClick={() =>
                            manageLicensedispatch({
                              type: ActionTypes.LICENSE_EDITING.SET,
                              payload: {
                                infiniteEndDate: true
                              }
                            })
                          }
                        />
                      </StyledInfinityButton>
                    </Tooltip>
                  </span>
                </TableCell>
                <TableCell colSpan={3} />
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Row>
        <Col style={{ position: 'relative' }}>
          {state.isEditing.value ? (
            <Button type="submit" onClick={handleUpdateChanges} disabled={!state.infiniteEndDate && !state.editStartDate && !state.editEndDate} loading={state.loading}>
              Update
            </Button>
          ) : state.addingLicense && state.licensePermisisons.create ? (
            <>
              <StyledRequiredSpan>* Required.</StyledRequiredSpan>
              <Button type="submit" onClick={handleAddLicenseDetails} loading={state.loading}>
                Confirm Add
              </Button>
              &nbsp;&nbsp;
              <OutlineButton type="submit" onClick={handleDiscardAdd}>
                Discard
              </OutlineButton>
            </>
          ) : state.licensePermisisons.create ? (
            <Button type="submit" onClick={() => manageLicensedispatch({ type: ActionTypes.LICENSE_EDITING.SET, payload: { addingLicense: true } })} data-cy="add-license-button">
              Add License
            </Button>
          ) : null}
        </Col>
      </Row>
    </ContentDiv>
  )
}

export default ManageLicenseDialog
