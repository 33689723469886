import styled from 'styled-components'

export const SimpleButton = styled.button<{ width?: string }>`
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-text);
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    filter: brightness(0.8);
  }

  &.bordered {
    border: 1px solid var(--color-background-light);

    border-radius: var(--dim-border-radius);
    white-space: nowrap;
    min-height: 3rem;
    min-width: ${(props: any) => props.width || '11rem'};
    font-weight: 400;
    font-size: .85rem;
    font-family: DigitalSerialBold;
    text-transform: uppercase;
    margin: 0.2rem 0;
    box-shadow: none;
    padding: 0 1rem;
    letter-spacing: .6px;
  }
`
