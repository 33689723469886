// @flow

import styled from 'styled-components'

import { StatusBannerType } from './useStatusBar'
import React from 'react'

function getColor (type: 'DANGER' | 'WARNING' | 'INACTIVE' | 'NONE') {
  switch (type) {
    case 'DANGER':
      return 'var(--color-alert-error)'
    case 'WARNING':
      return 'var(--color-alert-warning)'
    case 'INACTIVE':
      return 'var(--color-text-muted)'
    default:
      return 'var(--color-text-muted)'
  }
}

const StyledBanner = styled.a<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 5px 1rem;
  background-color: ${props => props.backgroundColor || 'transparent'};
  text-decoration: none;
  color: var(--color-lightest);
  cursor: pointer;
  font-family: open-sans-regular;

  &: hover {
    color: var(--color-lightest);
    text-decoration: none;
  }
`

const StatusBanner = React.memo(function StatusBanner ({ id, banner }: { id: string, banner: StatusBannerType }) {
  return (
    <StyledBanner
      id={id}
      backgroundColor={getColor(banner.type)}
      href={banner.url}
      target='_blank'
      dangerouslySetInnerHTML={{
        __html: banner.text
      }}>
    </StyledBanner>
  )
})

export default StatusBanner
