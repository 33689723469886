import { useEffect, useState } from 'react'

// Custom hook to detect if the tab is active or inactive
function usePageVisibility() {
  const [newDate, setNewDate] = useState<Date>(new Date())
  const [updateDependencies, setUpdateDependencies] = useState<boolean>(false)
  const [isPageVisible, setIsPageVisible] = useState<boolean>(document.visibilityState === 'visible')

  // Function to start the timer

  const checkUpdatedTime = () => {
    setIsPageVisible(document.visibilityState === 'visible')
  }

  useEffect(() => {
    if (!isPageVisible) {
      setNewDate(new Date())
      // Commenting this out for testing purposes
      // setUpdateDependencies(false)
    } else {
      const differenceInSeconds = Math.abs(new Date().getTime() - newDate.getTime()) / 1000
      setUpdateDependencies(differenceInSeconds > 60)
    }
  }, [isPageVisible])

  useEffect(() => {
    document.addEventListener('visibilitychange', checkUpdatedTime)
    return () => {
      document.removeEventListener('visibilitychange', checkUpdatedTime)
    }
  }, [])

  return [isPageVisible, updateDependencies]
}

export default usePageVisibility
