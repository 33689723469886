// @flow
import { useState } from 'react'
import styled from 'styled-components'

import { ALERT_TYPE, Alert } from 'components/ReUsable'
import { SimpleButton } from 'components/ReUsable/SimpleButton'
import SimpleDeleteMenu from 'components/ReUsable/SimpleDeleteMenu'
import { useSetInUse } from './hooks/useSetInUse'

const StyledMainContainer = styled.div`
  max-height: 300px;
  height: auto;
  clear: both;
  margin-top: .5rem;
  margin-bottom: 1.6rem;
`

const StyledFormContent = styled.div`
  border-radius: 4px;
  border: 1px solid var(--color-background-light);
  margin: 0 1rem;
  margin-top: 0;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: open-sans-regular;
  flex-wrap: wrap;
  box-sizing: content-box;
  
  @media (min-width: 991.98px) {
    margin: 0 4rem;
    padding: 28px;
  }

  & > p {
    color: #FFAF4F;
    margin-bottom: 0;
    margin-top: 10px;
  }
`

const StyledAlert = styled(Alert)`
  margin: 0 1.5rem;
  max-width: calc(100% - 3rem);
`

const SectionTitle = styled.p`
  color: var(--color-text);
  font-family: open-sans-regular;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 4rem;
  margin-bottom: 1rem;
`

const StyledPortState = styled.div`
  font-family: DigitalSerialBold;
`

const StyledPortUsedState = styled.div`
  font-family: DigitalSerialBold;
  color: var(--color-primary);
`

type FuelCartridgeInUseType = {
  serialNumber: string;
  portId: number;
  portEnabled: boolean;
  portActive: boolean;
}

export function FuelCartridgeInUse ({ serialNumber, portId, portEnabled, portActive }: FuelCartridgeInUseType) {
  const { error, loading, setInUseCall } = useSetInUse(serialNumber, portId)
  const [saveSuccess, setSaveSuccess] = useState<string>('')
  const [action, setAction] = useState<string>('')

  if(!portEnabled || (portId !== 2 && portId !== 4 )) return <></>

  const handleSetInUse = async () => {
    const succcess = await setInUseCall()

    if(succcess) {
      setSaveSuccess('Setting was successful.')
      setAction('')
    }
  }

  return (
    <StyledMainContainer>
      {error ? <><StyledAlert message={error} type={ALERT_TYPE.danger} /><br/></> : saveSuccess ? <><StyledAlert message={saveSuccess} type={ALERT_TYPE.success} /><br/></> : null}
      <div data-cy="advanced-config">
        <SectionTitle data-cy="">Advanced configuration:</SectionTitle>
        <StyledFormContent data-cy="ports-in-use">
          <div data-cy="label-port-state">Port state</div>
          {portActive ? <StyledPortUsedState data-cy="port-in-use">In use</StyledPortUsedState> : <StyledPortState data-cy="port-not-in-use" style={action === 'use' ? {visibility: 'hidden'} : {}}>Port not in use</StyledPortState>}
          { action === 'use' ? <><SimpleDeleteMenu text='Use this port?' onDelete={handleSetInUse} onCancel={()=>setAction('')} loading={loading}/><p data-cy="use-port-sure">Do you really want to use this FM port?<br/>This will change the currently used port to this port.</p></> : <SimpleButton data-cy="use-port-btn" style={portActive ? {visibility: 'hidden'} : {}} width="10rem" className='bordered' onClick={()=>setAction('use')}>Use this port</SimpleButton>}

        </StyledFormContent>
      </div>
    </StyledMainContainer>
  )
}
