// @flow

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/ReUsable'

import AlertConfigMessages from 'components/ReUsable/Alert/alertConfigMessages'

import { createXlsxExport, checkXlsxExport, getXlsxExport, clearXLSXExport } from 'actions'
import usePageVisibility from '../hooks/usePageVisibility'

const CenterContentDiv = styled.div`
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextSection = styled.p`
  color: var(--color-text-muted);
  font-family: open-sans-regular;
  font-size: 14px;
  font-weight: normal;
  padding: 20px 0 20px 0;
  text-align: center;
`

type Props = {
  pageDialog: {
    requestErrorMessage: string
  }
}

const deviceState = (state) => ({
  serialNumber: state?.devices?.current?.serialNumber,
  xlsxReport: state?.devices?.xlsxReport
})

export function XlsxExportDownload(props: Props) {
  const { pageDialog } = props

  const [isPageVisible] = usePageVisibility()

  const { serialNumber, xlsxReport } = useSelector(deviceState)
  const dispatch = useDispatch()
  const _createXlsxExport = (payload: { serialNumber: string }) => dispatch(createXlsxExport(payload))
  const _checkXlsxExport = (payload: { serialNumber: string; exportId: string }) => dispatch(checkXlsxExport(payload))
  const _getXlsxExport = (payload: { serialNumber: string; exportId: string }) => dispatch(getXlsxExport(payload))
  const _clearXLSXExport = () => dispatch(clearXLSXExport())

  useEffect(() => {
    // We were clearing here so I am changing it to create with taking off the flicker
    _createXlsxExport({ serialNumber })
  }, [])

  useEffect(() => {
    if (!isPageVisible && !xlsxReport.exportId) _createXlsxExport({ serialNumber })
  }, [isPageVisible])

  useEffect(() => {
    const pool = setInterval(() => {
      if (xlsxReport.exportId && (xlsxReport.status === null || xlsxReport.status === 'processing')) {
        return _checkXlsxExport({ serialNumber, exportId: xlsxReport.exportId })
      }
    }, 5000)

    return () => clearInterval(pool)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xlsxReport.exportId, xlsxReport.status])

  useEffect(() => {
    return () => {
      _clearXLSXExport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (xlsxReport.status === null || xlsxReport.status === 'processing') {
    return (
      <CenterContentDiv>
        <AlertConfigMessages pageDialog={pageDialog} />
        <TextSection>Generating XLSX Report</TextSection>
        <Spinner animation="border" variant="secondary" />
      </CenterContentDiv>
    )
  }

  return (
    <CenterContentDiv>
      <TextSection>XLSX report is ready</TextSection>
      {xlsxReport.fileLimitExceeded && (
        <TextSection>
          Unfortunately the report is not complete, data limit exceeded. <br />
          Data range was too large.
        </TextSection>
      )}
      <Button onClick={() => _getXlsxExport({ serialNumber, exportId: xlsxReport.exportId })}>XLSX Report</Button>
    </CenterContentDiv>
  )
}

export default XlsxExportDownload
