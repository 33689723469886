// @flow

import { EmptyElement } from 'components/ReUsable'
import { getNumberWithDecimals } from 'utils/helpers'

type Props = {
  data: any
  property?: string
  properties?: Array<any>
  suffix?: string
  showMessage?: boolean
  min?: number
  padStart?: number
  showZero?: boolean
  noDigits?: boolean
  decimals?: number
}

export default function ShowDataValue(props: Props) {
  const { data, property, properties, suffix, showMessage, min, padStart, showZero, noDigits, decimals } = props

  let displayedValue: any = ['?']

  if (data && property && (data[property] || (data[property] === 0 && showZero))) {
    displayedValue = padStart ? `${data[property]}`.padStart(padStart, '0') : `${data[property]}`
    displayedValue = min !== undefined && displayedValue * 1 < min ? `${min}` : displayedValue

    if (noDigits) {
      displayedValue = Math.trunc(Number(displayedValue))
      displayedValue = String(displayedValue)
    }

    if (decimals) {
      displayedValue = getNumberWithDecimals(Number(displayedValue), decimals)
      displayedValue = String(displayedValue)
    }

    return (suffix && displayedValue.concat(` ${suffix}`)) || displayedValue
  }

  if (data && properties) {
    displayedValue = ''
    properties.forEach((property) => {
      if (data[property]) {
        displayedValue += '.' + (padStart ? `${data[property]}`.padStart(padStart, '0') : `${data[property]}`)
      }
    })
    // cut off first dot
    displayedValue = displayedValue.slice(1)

    if (displayedValue) {
      return (suffix && displayedValue.concat(` ${suffix}`)) || displayedValue
    }
  }

  if (showMessage === false) {
    displayedValue = min !== undefined && displayedValue * 1 < min ? `${min}` : displayedValue
    return (suffix && displayedValue.concat(` ${suffix}`)) || displayedValue
  }

  return <EmptyElement>none</EmptyElement>
}
