// @flow

import styled from 'styled-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import ClaimState from './detailClaimDateIndicator'
import { getProperty } from 'utils/helpers'
import {
    togglePageDialog
  } from 'actions'
import { DEVICE_DETAILS_PERMISSIONS_LIST, GRANTED } from 'appConstants'

type LicenseProps = {
    tier: string;
    end?: Date;
}

const StyledDeviceTypeText = styled.span`
    text-transform: capitalize;
    font-size: 12px;
`
const StyledLicenseText = styled.span`
    font-size: 12px;
    display: inline-block;
    &:first-letter{
        text-transform: capitalize;
    }
    &:hover {
        text-decoration: ${(props: {showLink: boolean}) => props.showLink ? 'underline' : 'none'};
        cursor: ${(props: {showLink: boolean}) => props.showLink ? 'pointer' : 'default'};
    }
`

const StyledInfoText = styled.span`
    font-size: 12px;
`

const StyledInfoTextSerialNumber = styled.span`
    display: inline-block;
    font-size: 12px;
    margin-bottom: 8px;
`

const selectDeviceState = (state) => ({
    device: state?.devices?.current
  })

const GeneralInfo = () => {
    const { device } = useSelector(selectDeviceState)
    const deviceOwner = getProperty(device.owner) || {}
    const dispatch = useDispatch()
    const permissions = device.permissions || {}
    const isLicenseAddGranted = permissions[DEVICE_DETAILS_PERMISSIONS_LIST.LICENSE_ADD] === GRANTED
    const _togglePageDialog = (payload?: any) => dispatch(togglePageDialog(payload))

    const license = device.license as LicenseProps
    const { inclusiveLicenseAvailable } = device
    return <>
        <div>
        <StyledDeviceTypeText>{`${device.deviceType} - `}</StyledDeviceTypeText>
        <StyledLicenseText
            showLink={isLicenseAddGranted}
            onClick={() =>
                isLicenseAddGranted ? _togglePageDialog({ dialogId: 'add-license-dialog' }) : null
            }
        >
            {license ? `Active ${(license).tier} license (expires: ${(license).end ? moment((license).end).format('DD.MM.yyyy') : 'never'})` : 'no license'}
            {inclusiveLicenseAvailable ? ' (inclusive one available)' : ''}
        </StyledLicenseText>
        </div>
        <StyledInfoTextSerialNumber>Serial Number: {device.serialNumber}</StyledInfoTextSerialNumber>
        <StyledInfoText><ClaimState date={deviceOwner.claimDate} /></StyledInfoText>
    </>
}

export default GeneralInfo
