// @flow

import { IconButton } from 'components/ReUsable'

import { DENIED, DENIED_MISSING_LICENSE, GRANTED } from 'appConstants'
import { useDispatch, useSelector } from 'react-redux'
import { togglePageDialog } from 'actions'
import { useMemo } from 'react'
import { CurrentDeviceType } from 'types/devices'

function checkStatusForPermissions(state: any, permissionsToCheck: string[]) {
  const permissionsObj: Record<string, string> = (state?.devices?.current as CurrentDeviceType).permissions
  //  Use these lines to edit permission for testing
  //  let permissionsObj: Record<string, string> = (state?.devices?.current as CurrentDeviceType).permissions
  //  permissionsObj = { ...permissionsObj, 'device:operation_mode:write': DENIED_MISSING_LICENSE }

  //  If at least one permission is granted, we return granted.
  const isMissingLicense = permissionsToCheck.every((pStr: string) => permissionsObj[pStr] === DENIED_MISSING_LICENSE)
  let isGranted = permissionsToCheck.some((pStr: string) => permissionsObj[pStr] === GRANTED)
  let isDenied = permissionsToCheck.every((pStr: string) => permissionsObj[pStr] === DENIED)

  //  This logic is needed on FuelCellSection.tsx and this is the only place which sends multiple permissionsToCheck
  if (permissionsToCheck.length > 1) {
    const logicForMultipleItems = permissionsObj[permissionsToCheck[0]] !== GRANTED && permissionsObj[permissionsToCheck[1]] !== GRANTED ? DENIED : GRANTED
    isGranted = logicForMultipleItems === GRANTED
    isDenied = logicForMultipleItems === DENIED
  }
  return { isGranted, isDenied, isMissingLicense }
}

export const UpgradeLicenseButton = function ({
  isConnected,
  permissionNames,
  handleClick,
  defaultButton,
  dataCy
}: {
  isConnected: boolean
  permissionNames: string[]
  handleClick: () => void
  defaultButton?: string | undefined
  dataCy?: string
}) {
  const dispatch = useDispatch()

  const { isGranted, isMissingLicense } = useSelector((state: any) => useMemo(() => checkStatusForPermissions(state, permissionNames), [state, permissionNames]))
  const onButtonClick = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (isConnected) {
      if (isGranted) {
        handleClick()
      }
      if (isMissingLicense) {
        dispatch(togglePageDialog({ dialogId: 'license-missing-dialog' }))
      }
    }
  }

  if (!isMissingLicense) {
    return (
      <IconButton
        dataCy={dataCy}
        {...{
          disabled: !isConnected || !isGranted
        }}
        onClick={onButtonClick}
        src={defaultButton || 'manage'}
      />
    )
  }
  //  License missing, show upgrade license button.
  return <IconButton {...{ disabled: !isConnected }} src="upgrade" alt="Upgrade your license" width="26px" onClick={onButtonClick} dataCy={dataCy} />
}

export default UpgradeLicenseButton
