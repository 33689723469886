// @flow

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { updatePortInUse } from 'apis/devices'
import { delay } from 'utils/helpers'
import { getDevice } from 'actions'

type SetInUseType = {
  loading: boolean
  error: string
  setInUseCall: () => Promise<boolean>
}

export function useSetInUse (serialNumber: string, portId: number): SetInUseType {
  const dispatch = useDispatch()
  const _getDevice = (payload: any) => dispatch(getDevice(payload))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const setInUseCall = async () => {
    try {
      setLoading(true)
      setError('')
      const inUseResponse = await updatePortInUse(serialNumber, portId)
      if (inUseResponse && inUseResponse?.status === 204) {
        await delay(2000)
        _getDevice({ serialNumber })
        return true
      } else {
        throw new Error(inUseResponse?.detail || 'Something went wrong.')
      }
    } catch (e: any) {
      _getDevice({ serialNumber })
      await delay(2000)
      setError(e.message)
      return false
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, setInUseCall }
}
