// @flow

import styled from 'styled-components'
import { connect } from 'react-redux'

import {
  Dialog,
  FuelCartridge,
  UpgradeLicenseButton
} from 'components/ReUsable'

import {
  togglePageDialog,
  resetCatridgeMessage
} from 'actions'

import { roundedNumber } from 'utils/helpers'

import { DEVICE_DETAILS_PERMISSIONS_LIST } from 'appConstants'

import FuelCartridgesEditDialog from './fuelCartridgesEditDialog'

const List = styled.div`
  margin: 0 -0.2rem;
`

const ListItem = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  padding: 1rem 0;
`

const Info = styled.div`
  display: grid;
  flex: 1 0 auto;
  margin-left: 1rem;
  grid-template-areas:
    "name edit"
    "activeport edit"
    "fuellevel edit";
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 3rem;
`

const Name = styled.div`
  grid-area: name;
  color: var(--color-text-light);
  font-family: open-sans-regular;
`

const ActivePort = styled.div`
  grid-area: activeport;
  color: var(--color-text-light);
  font-family: open-sans-regular;
`

const FuelLevel = styled.div`
  grid-area: fuellevel;
  padding-top: 1rem;
`

const FuelLevelTitle = styled.div`
  color: var(--color-text-light);
  font-family: open-sans-regular;
`

const FuelLevelValue = styled.div`
  font-family: digital-serial;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-table-text);
`

const EditButtonWrapper = styled.div`
  grid-area: edit;
  font-size: 1.7rem;
`

type Props = {
  fuelCartridges: {
    remainingTotalFuelPercent: string,
    pageDialog: any,
    connectedCartridges: Array<any>,
    maxNumberOfCartridges: number
  },
  dialog: {
    isPageDialogOpen: {
      isPageDialogOpen: boolean
    },
    dialogId?: string
  },
  togglePageDialog: (obj?: any) => void,
  resetCatridgeMessage: () => void,
  permissions?: any,
  isConnected?: boolean
}

const FuelCartridgesListSection = (props: Props) => {
  const {
    togglePageDialog,
    fuelCartridges,
    resetCatridgeMessage,
    isConnected,
    permissions
  } = props
  const { isPageDialogOpen, dialogId } = props.dialog

  const cartridgeTitle = (item: any) => {
    if (item.cartridgeType === 'UNSPECIFIED') {
      return `${item.portNumber}. Not configured`
    } else if (item.portEnabled === false) {
      return `${item.portNumber}. Port disabled`
    }

    return `${item.portNumber}. ${item.cartridgeType} (${item.capacity}L)`
  }

  const renderItem = (item: any, index: number) => {
    return (
      <ListItem key={index}>
        <FuelCartridge fuelCartridge={item} large={true}></FuelCartridge>
        <Info>
          <Name>{cartridgeTitle(item)}</Name>
          { item.portActive === true &&
            <ActivePort>Port in use</ActivePort>
          }
          <FuelLevel>
            <FuelLevelTitle>Fuel Level</FuelLevelTitle>
            <FuelLevelValue>{`${item.portEnabled ? roundedNumber(item.fuelLevel) : '\u2212'} % (${(item.capacity * (item.fuelLevel / 100)).toFixed(2)}l)`}</FuelLevelValue>
          </FuelLevel>
          { fuelCartridges.remainingTotalFuelPercent !== null &&
            <EditButtonWrapper>
              <UpgradeLicenseButton
                dataCy={ `edit-cartridge-${item.portNumber}` }
                isConnected={ isConnected as boolean }
                handleClick={() => togglePageDialog({ dialogId: `edit-fuel-cartridges-dialog-${index}` })}
                permissionNames={[DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_LEVEL_WRITE]}/>
            </EditButtonWrapper>
          }
        </Info>

        <Dialog
          key={`dialog-${index}`}
          id={`edit-fuel-cartridges-dialog-${index}`}
          className='modal-page'
          title={ cartridgeTitle(item) }
          show={ isPageDialogOpen && dialogId === `edit-fuel-cartridges-dialog-${index}` }
          onClose={ () => {
            togglePageDialog()
            resetCatridgeMessage()
          } }
        >
          <FuelCartridgesEditDialog
            fuelCartridge={ item }
            maxNumberOfCartridges={ fuelCartridges.maxNumberOfCartridges }
            previousId = {index > 0 ? `edit-fuel-cartridges-dialog-${index - 1}` : null}
            nextId = {index < (fuelCartridges.maxNumberOfCartridges - 1) ? `edit-fuel-cartridges-dialog-${index + 1}` : null}
            permissions={permissions}
          />
        </Dialog>
      </ListItem>
    )
  }

  return (
    <>
    <List>
      { fuelCartridges && fuelCartridges.connectedCartridges.map((item, index) => {
        return renderItem(item, index)
      })}
    </List>
    </>
  )
}

const mapState = (state: any) => (
  {
    fuelCartridges: state.devices?.current?.details?.content?.fuelCartridges,
    pageDialog: state.devices?.pageDialog,
    dialog: state.dialog
  }
)

const mapDispatch = {
  togglePageDialog,
  resetCatridgeMessage
}

export default connect(
  mapState,
  mapDispatch
)(FuelCartridgesListSection)
