// @flow
import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { ALERT_TYPE, Alert } from 'components/ReUsable'
import { SectionTitle } from 'components/ReUsable/Dialog'
import { SimpleButton } from 'components/ReUsable/SimpleButton'
import SimpleDeleteMenu from 'components/ReUsable/SimpleDeleteMenu'
import { MultiSenseActionType, useMultisense } from './hooks/useMultisense'
import { MultiSenseType } from '../Types/DeviceTypes'

const StyledFormContainer = styled.div`
  height: 280px;

  @media (min-width: 991.98px) {
    height: 320px;
  }
`

const StyledFormContent = styled.div`
  border-radius: 4px;
  border: 1px solid var(--color-background-light);
  margin: 0 1rem;
  margin-top: 1.6rem;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: open-sans-regular;
  flex-wrap: wrap;
  box-sizing: content-box;
  
  &:first-child {
    margin-top: 1rem;
  }
  
  @media (min-width: 991.98px) {
    margin: 0 4rem;
    margin-top: 2.2rem;
    padding: 28px;

    &:first-child {
      margin-top: 1.2rem;
    }
  }

  & > p {
    color: #FFAF4F;
    margin-bottom: 0;
    margin-top: 10px;
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: 0;
`

type ResetFormType = {
  serialNumber: string;
  multiSenseType: MultiSenseType
}

export function ResetForm ({ serialNumber, multiSenseType }: ResetFormType) {
  const { loading, saveError, resetDeviceMultisenseCall } = useMultisense(serialNumber, multiSenseType)
  
  const [actionType, setActionType] = useState<MultiSenseActionType | ''>('')
  const [saveSuccess, setSaveSuccess] = useState<string>('')
  const { isPageDialogOpen, dialogId } = useSelector((state: any) => state.dialog)

  if (!isPageDialogOpen || dialogId !== 'reset-multisense-dialog') {
    return null
  }

  const handleAction = (type: MultiSenseActionType) => {
    setActionType(type)
  }

  const handleReset = async () => {
    const successText = actionType === 'disable' ? 'The action deactivate all ports was dispatched successfully.' : 'The reset to factory defaults was dispatched successfully.' 
    if(!actionType) return
    const updateCompleted = await resetDeviceMultisenseCall(actionType)
    if (updateCompleted) {
      setSaveSuccess(successText)
    }
    setActionType('')
  }

  return (
    <>
      {saveError ? <StyledAlert message={saveError} type={ALERT_TYPE.danger} /> : saveSuccess ? <StyledAlert message={saveSuccess} type={ALERT_TYPE.success} /> : null}
      <SectionTitle data-cy="multisense-reset-form-title">All Ports</SectionTitle>
      <StyledFormContainer>
        <StyledFormContent data-cy="ports-deactivation">
          <div>Ports deactivation</div>
          { actionType === 'disable' ? <SimpleDeleteMenu text='Deactivate all ports?' onDelete={handleReset} onCancel={()=>setActionType('')} loading={loading}/> : <SimpleButton width="17rem" className='bordered' onClick={()=>handleAction('disable')}>Deactivate all ports</SimpleButton>}
          { actionType === 'disable' ? <p>Are you sure you want to deactivate all ports of the EFOY MultiSense?</p> : null }
        </StyledFormContent>
        <StyledFormContent data-cy="factory-defaults">
          <div>Factory defaults</div>
          { actionType === 'reset' ? <SimpleDeleteMenu text='Reset?' onDelete={handleReset} onCancel={()=>setActionType('')} loading={loading} /> : <SimpleButton width="17rem" className='bordered' onClick={()=>handleAction('reset')}>Reset</SimpleButton>}
          { actionType === 'reset' ? <p>Are you sure you want to reset the EFOY MultiSense to factory settings?</p> : null }
        </StyledFormContent>
       </StyledFormContainer>
    </>
  )
}
