import styled from 'styled-components'
import { AnalogPort, IOPort, TemperaturePort, AnalogPortForm, TemperaturePortForm, IOPortForm, Warning } from '../hooks/useMultisense'
import { MultiSenseType } from '../../Types/DeviceTypes'

const StyledPortType = styled.span`
  color: var(--color-text-light);
  font-size: 16px;
  font-style: normal;
  font-family: open-sans-regular;
  font-weight: 400;
  line-height: 18px;
  border: 1px solid var(--color-text-light);
  padding: 0 1rem;
  border-radius: 1rem;
  min-width: 54px;
  display: inline-block;
  text-align: center;
  margin-top: 0.115rem;
`

const StyledPortLabel = styled.span`
  color: var(--color-text-light);
  font-family: open-sans-regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const StyledValueLabel = styled.div`
  position: absolute;
  left: 78px;
  bottom: 10px;
  font-size: 25px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: -1.2px;
  font-family: digital-serial;
`

const StyledFunctionIcon = styled.img`
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: auto;
  height: ${(props: { io: boolean }) => (props?.io ? '30px' : '45px')};
`

const StyledAlertIcon = styled.img`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 25px;
  height: auto;
`

const StyledWarningIcon = styled.img<{ type?: string | undefined }>`
  position: ${(props: any) => (props?.type === 'error' ? 'static' : 'absolute')};
  left: ${(props: any) => (props?.type === 'error' ? '0' : '10px')};
  bottom: ${(props: any) => (props?.type === 'error' ? '0' : '10px')};
  width: auto;
  height: ${(props: any) => (props?.type === 'error' ? '30px' : '45px')};
`

const defaultAnalogForm = {
  enabled: true,
  sensor: 'custom',
  function: 'not_configured',
  unit: 'A'
}

const functionLabelsMapping = {
  analog: {
    solar: 'Solar',
    battery: 'Battery',
    load: 'Load',
    custom: 'Custom',
    efoy: 'EFOY'
  },
  temperature: {
    ambient: 'Ambient',
    battery: 'Battery temperature',
    indoor: 'Indoor temperature',
    outdoor: 'Outdoor temperature',
    cartridge: 'Cartridge temperature',
    custom: 'Custom temperature'
  },
  io: {
    fuel_sensor: 'EFOY fuel sensor',
    door: 'Door',
    light: 'Light',
    fan: 'Fan',
    load_relay: 'Load relay',
    custom: 'Custom'
  }
}

export const unitMapping = {
  watt: 'W',
  volt: 'V',
  ampere: 'A',
  celsius: '°C'
}

const ioPortValueMapping = {
  fuel_sensor: { true: 'Above', false: 'Below' },
  door: { true: 'Open', false: 'Closed' },
  light: { true: 'On', false: 'Off' },
  fan: { true: 'On', false: 'Off' },
  load_relay: { true: 'Open', false: 'Closed' },
  custom: { true: 'High', false: 'Low' }
}

export function PortType({ name }: { name: string }): JSX.Element {
  return <StyledPortType>{name.toUpperCase()}</StyledPortType>
}

export function PortLabel({ enabled, label, portKey }: { enabled: boolean; label: string; portKey: string }): JSX.Element {
  if (!enabled) {
    return <StyledPortLabel>Port deactivated</StyledPortLabel>
  }
  const type = getPortTypeAsString(portKey)
  return <StyledPortLabel>{functionLabelsMapping[type][label] || label}</StyledPortLabel>
}

export function FunctionIcon({ portKey, portSettings }: { portKey: string; portSettings: AnalogPort | TemperaturePort | IOPort }): JSX.Element | null {
  const { enabled } = portSettings
  if (portKey.startsWith('a')) {
    return <StyledFunctionIcon io={false} data-cy="multisense-function-icon" src={`/imgs/flash${enabled ? '' : '_disabled'}.svg`} alt={'Analog'} title={'Analog'} />
  }

  if (portKey.startsWith('t')) {
    return <StyledFunctionIcon io={false} data-cy="multisense-function-icon" src={`/imgs/temperature${enabled ? '' : '_disabled'}.svg`} alt={'Temperature'} title={'Temperature'} />
  }
  if (portKey.startsWith('io')) {
    const { direction } = portSettings as IOPort
    return <StyledFunctionIcon io={true} data-cy="multisense-function-icon" src={`/imgs/io_${direction}${enabled ? '' : '_disabled'}.svg`} alt={direction} title={direction} />
  }
  return null
}

export function AlertIcon({ enabled, alert }: { enabled: boolean; alert: 'enabled' | 'disabled' | 'active' }): JSX.Element | null {
  if (!enabled || alert === 'disabled') {
    return null
  }
  return <StyledAlertIcon data-cy="multisense-alert-icon" src={`/imgs/alert_${alert}.svg`} alt={`Alert ${alert}`} title={`Alert ${alert}`} />
}

const ERROR_PAGE_URL = 'https://www.efoy-pro.com/en/service/servicetool/?product=##product##&errorcode=##errorcode##'

export function WarningIcon({ warning, deviceType, type, dataCy }: { warning: Warning | null; deviceType: string; type?: string; dataCy?: string }): JSX.Element | null {
  if (!warning || !deviceType) return null
  const errorCode = formatWarningVersion(warning)
  const product = deviceType.replace(/ /g, '-').toLowerCase()
  const errorPageStr = ERROR_PAGE_URL.replace('##errorcode##', errorCode).replace('##product##', product)
  return (
    <a href={errorPageStr} target="_blank" rel="noreferrer" data-cy={dataCy || ''}>
      <StyledWarningIcon
        data-cy="multisense-warning-icon"
        src={`/imgs/${type === 'error' ? 'warning-red.svg' : 'warning.svg'}`}
        alt={`Warning ${errorCode}`}
        title={`Warning ${errorCode}`}
        type={type}
      />
    </a>
  )
}

function formatWarningVersion(warning: Warning): string {
  const majorStr = warning.major.toString().padStart(3, '0')
  const minorStr = warning.minor.toString().padStart(3, '0')
  return `${majorStr}.${minorStr}`
}

export function ValueLabel({ enabled, functionName, value, unit }: { enabled: boolean; functionName: string; value: number | boolean; unit?: string }): JSX.Element | null {
  if (!enabled) return null
  if (typeof value === 'number' && unit) {
    //  Show some number value with unit mapping.
    return <StyledValueLabel>{`${value.toFixed(1)} ${unitMapping[unit] || ''}`}</StyledValueLabel>
  } else if (typeof value === 'boolean' && functionName in ioPortValueMapping) {
    return <StyledValueLabel>{ioPortValueMapping[functionName][value.toString()] || ''}</StyledValueLabel>
  }
  return null
}

export function getPortTypeAsString(portKey: string): 'analog' | 'temperature' | 'io' {
  return portKey.startsWith('a') ? 'analog' : portKey.startsWith('t') ? 'temperature' : 'io'
}

export function getMultiSenseTypeScreenName(multiSenseType: MultiSenseType) {
  if (multiSenseType === 'none') {
    return ''
  }
  const suffix = multiSenseType === 'multi_sense_8' ? '8' : '4'
  return `EFOY MultiSense MS${suffix}`
}

const defaultTemperatureForm = {
  enabled: true,
  function: 'not_configured'
}

const defaultIOForm: IOPortForm = {
  enabled: true,
  function: 'not_configured',
  direction: 'input',
  inverted: false,
  value: true,
  defaultOutput: false,
  errorOutput: false,
  errorOutputEnabled: false
}

export function fillObject(configKey: string, configValues: AnalogPort | TemperaturePort | IOPort | null): AnalogPortForm | TemperaturePortForm | IOPortForm {
  const configType: string = configKey[0]
  if (!configValues) {
    return configType === 'a' ? defaultAnalogForm : configType === 't' ? defaultTemperatureForm : defaultIOForm
  }

  if (configType === 'a') {
    const configObj = configValues as AnalogPort
    return {
      enabled: configObj.enabled,
      sensor: configObj.sensor,
      function: configObj.function,
      unit: configObj.sensor === 'custom' ? 'volt' : configObj.unit
    } as AnalogPortForm
  } else if (configType === 't') {
    const configObj = configValues as TemperaturePort
    return {
      enabled: configObj.enabled,
      function: configObj.function
    } as TemperaturePortForm
  } else {
    const configObj = configValues as IOPort
    return {
      enabled: configObj.enabled || false,
      function: configObj.function,
      direction: configObj.direction,
      inverted: configObj.inverted || false,
      value: configObj.value || false,
      currentLimit: configObj.currentLimit || 0,
      defaultOutput: configObj.defaultOutput || false,
      errorOutput: configObj.errorOutput || false,
      errorOutputEnabled: configObj.errorOutputEnabled || false
    } as IOPortForm
  }
}

export function portScreenName(portKey: string) {
  let prefixStr = `Port ${portKey}`
  if (portKey === 'a1' || portKey === 'a2') {
    prefixStr = `Analog Port ${portKey[1]}`
  } else if (portKey === 't1' || portKey === 't2') {
    prefixStr = `Temperature Port ${portKey[1]}`
  } else if (portKey.indexOf('io') === 0) {
    prefixStr = `IO Port ${portKey[2]}`
  }
  return prefixStr
}
