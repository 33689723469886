// @flow
import styled from 'styled-components'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TrashButton
} from 'components/ReUsable'

export const StyledRemoveMenu = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0 !important;

  & > p {
    margin-bottom: 0;
    font-family: var(--bs-body-font-family);
    color: var(--color-text);
  }
`

export const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
`

export const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-alert-error);
  cursor: pointer;
  font-size: 18px;
`

function DeleteMenu ({ onDelete, onStartDelete, text }: { onDelete: () => void, onStartDelete?: () => void, text?: string }) {
  const [startDelete, setStartDelete] = useState(false)
  return (
    <>
      {startDelete ? (
        <StyledRemoveMenu>
          <p>{text || 'Delete?'}</p>&nbsp;&nbsp;
          <StyledPrimaryFontAwesomeIcon data-cy="remove-item" icon={['far', 'circle-check'] as any} onClick={
            () => {
              setStartDelete(false)
              onDelete()
            }
          } />
          &nbsp;&nbsp;
          <StyledAlertFontAwesomeIcon data-cy="cancel-remove-item" icon={['far', 'circle-xmark'] as any} onClick={() => setStartDelete(false)} />
        </StyledRemoveMenu>
      ) : (
        <TrashButton onClick={() => {
          setStartDelete(true)
          onStartDelete?.()
        }} dataCy="start-remove-item" bordered={true}/>
      )}
    </>
  )
}

export default DeleteMenu
