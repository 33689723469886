// @flow

import { ButtonOption } from 'components/ReUsable'
import { NEW_API_ACTIVE } from '../../../../../src/featureToggles'
import { useDispatch } from 'react-redux'
import {
  togglePageDialog
} from 'actions'

export function DownloadReportButton () {
  const dispatch = useDispatch()
  const _togglePageDialog = (payload?: any) => dispatch(togglePageDialog(payload))
  if (!NEW_API_ACTIVE) {
    return null
  }
  return (
    <ButtonOption
      onClick={() =>
        _togglePageDialog({ dialogId: 'device-export-dialog' })
      } /*  */
      label="Download Report"
      secondary={true}
      dark={true}
    />
  )
}

export default DownloadReportButton
