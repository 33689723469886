// @flow

import { useDispatch } from 'react-redux'

import { useFormik } from 'formik'
import { Form, Button, Input, Row, Col } from 'components/ReUsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'

import { applyDeviceFilter, getDevices, togglePageDialog } from 'actions'

type Props = {
  devices: {
    totalItems: number
    search: {
      searchValue: string
    }
  }
  sorting:
    | {
        sortKey: string
        sortDirection: string
      }
    | any
  formInitialValues: any
  handleSearchFormSubmit?: (values: any) => void
}

const DeviceCountRow = styled.div`
  padding: 0.5rem 0.7rem;
  font-family: open-sans-bold;
  color: var(--color-text-muted);
  font-size: 11px;
`

const StyledSearchForm = styled(Row)`
  gap: 10px;
`

export function SearchForm(props: Props) {
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: props.devices.search,
    onSubmit: (values) => {
      dispatch(applyDeviceFilter(null))
      const updatedValues = Object.assign({ sorting: { ...props.sorting } }, values)
      dispatch(getDevices(updatedValues))
    }
  })

  return (
    <Form handleSubmit={formik.handleSubmit} id={'search-form'}>
      <StyledSearchForm>
        <Col>
          <div className="input-group">
            <span className="input-group-append">
              <span className="input-group-text border-right-0">
                <FontAwesomeIcon icon={faMagnifyingGlass as any} size="lg" className="icon-dark-mode-ready" />
              </span>
            </span>
            <Input
              id={'searchValue'}
              type={'text'}
              name={'searchValue'}
              className={'form-control border-left-0 search-input'}
              placeholder={'Search for Serial / Name'}
              onChange={formik.handleChange}
              value={formik.values.searchValue}
            />
          </div>
        </Col>
        <Col>
          <Button type="button" id={'button-search-form'} className="btn-add-device" onClick={() => dispatch(togglePageDialog({ dialogId: 'device-claiming-dialog' }))}>
            <span className="btn-add-device-content">
              <FontAwesomeIcon icon={['fal', 'plus']} size="sm" /> ADD EFOY
            </span>
          </Button>
        </Col>
      </StyledSearchForm>
      <DeviceCountRow>Total devices: {props.devices.totalItems}</DeviceCountRow>
    </Form>
  )
}

export default SearchForm
