// @flow
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'react-bootstrap'

export const StyledRemoveMenu = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0 !important;

  & > p {
    margin-bottom: 0;
    font-family: var(--bs-body-font-family);
    color: var(--color-text);
  }
`

export const StyledPrimaryFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  cursor: pointer;
  font-size: 18px;
`

export const StyledAlertFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: var(--color-alert-error);
  cursor: pointer;
  font-size: 18px;
`

function SimpleDeleteMenu ({ onDelete, text, onCancel, loading }: { onDelete: () => void, text?: string, onCancel?: () => void, loading?: boolean }) {
  return (
    <StyledRemoveMenu>
      <p>{text || 'Delete?'}</p>&nbsp;&nbsp;
      { loading ? <Spinner animation='border' variant='secondary' size='sm' /> : 
      <>
        <StyledPrimaryFontAwesomeIcon data-cy="remove-item" icon={['far', 'circle-check'] as any} onClick={
          () => {
            onDelete()
          }
        } />
        &nbsp;&nbsp;
        <StyledAlertFontAwesomeIcon data-cy="cancel-remove-item" icon={['far', 'circle-xmark'] as any} onClick={() => {
          onCancel?.()
        }} />
      </>
      }
    </StyledRemoveMenu>
  )
}

export default SimpleDeleteMenu
