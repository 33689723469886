// @flow

import { saveAs } from 'file-saver'
import moment from 'moment'

import { backendEndpoint, executeRequest } from '.'
import { AnalogPortForm, TemperaturePortForm } from 'components/Application/Device/Multisense/hooks/useMultisense'
import { defaultDeviceColumns } from 'reducers/devicesReducer'
import { NEW_API_ACTIVE } from '../../src/featureToggles'

const devicesEndpoint = `${backendEndpoint || ''}/devices`

const batchEndpoint = `${backendEndpoint || ''}/batch`

const exportsEndpoint = `${backendEndpoint || ''}/export`

const licenseEndpoint = `${backendEndpoint || ''}/licenses`

const accountPreferencesEndpoint = `${backendEndpoint || ''}/account/preferences`

const addDayIfIsEndDate = (dateField: any, isEndDate: boolean) => {
  const fieldVal = (document.getElementById(dateField) as any).value

  let momentObj

  if (!Number.isNaN(+fieldVal)) {
    momentObj = moment(+fieldVal)
  } else {
    momentObj = moment((document.getElementById(dateField) as any).value)
  }

  if (isEndDate) {
    momentObj.add(1, 'days')
  }

  return momentObj.unix()
}

// TODO: we might change this endpoint in the future
export const getDeviceRequest = (serialNumber: string) => executeRequest(`${devicesEndpoint}/${serialNumber}`)

export const getDevicesRequest = (page: number, searchValue: string, sorting: any, filter?: string) => {
  //  All filter values are collected as a JSON object with keys and values.
  //  Take each key and add it with it's value as query parameter.
  let filterStr = ''
  const filterObj = filter ? JSON.parse(filter) : {}
  Object.keys(filterObj).map((k: string) => {
    if (k === 'compoundState') {
      const activeArr = filterObj[k].active
      const inactiveArr = filterObj[k].inactive
      if (activeArr?.length > 0) {
        filterStr = `${filterStr}&csActive=${activeArr}`
      }
      if (inactiveArr?.length > 0) {
        filterStr = `${filterStr}&csInactive=${inactiveArr}`
      }
    } else {
      filterStr = `${filterStr}&${k}=${encodeURIComponent(filterObj[k])}`
    }
  })

  return executeRequest(`${devicesEndpoint}?page=${page || 1}`, searchValue, sorting, undefined, filterStr)
}

export const updateDeviceNameRequest = (serialNumber: string, name: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ name: name })
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/name`, undefined, undefined, options)
}

export const setInclusiveLicenseRequest = (serialNumber: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'POST',
    body: 'inclusive'
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/licenses`, undefined, undefined, options)
}

export const updateOperatingModeRequest = (serialNumber: string, parameter: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ parameter })
  }
  return executeRequest(`${devicesEndpoint}/${serialNumber}/operation-mode`, undefined, undefined, options)
}

export const resetFuelCellRequest = (serialNumber: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT'
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/reset`, undefined, undefined, options)
}

export const resetChargingCycleRequest = (serialNumber: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT'
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/reset-charging-cycles`, undefined, undefined, options)
}

export const resetFuelCellStatusRequest = (serialNumber: string) => executeRequest(`${devicesEndpoint}/${serialNumber}/reset`)

export const telemetryHistoryRequest = (serialNumber: string, page: number, size: number, timeScale: string, startTimestamp: number, endTimestamp: number) =>
  executeRequest(
    `${devicesEndpoint}/${serialNumber}/history?page=${page || 0}&size=${size || 750}&timescale=${timeScale || 'HOURS'}&startTimestamp=${startTimestamp || 0}&endTimestamp=${endTimestamp || 0}`,
    undefined,
    undefined
  )

export const telemetryEventsRequest = (serialNumber: string, page: number, size: number, timeScale: string, startTimestamp: number, endTimestamp: number) =>
  executeRequest(`${devicesEndpoint}/${serialNumber}/events?page=${page || 0}&size=${size || 750}&startTimestamp=${startTimestamp || 0}&endTimestamp=${endTimestamp || 0}`, undefined, undefined)

export const createDeviceExportRequest = (serialNumber: string) => executeRequest(`${exportsEndpoint}/start/${serialNumber}`)

export const checkDeviceExportRequest = (id: string) => executeRequest(`${exportsEndpoint}/check/${id}`)

export async function getDeviceExportRequest(id: string) {
  // TODO: Check with BE if they you provide a url to download the file.
  // If necessary use the request with the token.
  // This is not ideal to do, we need a url to download the file.
  const blob = await executeRequest(`${exportsEndpoint}/download/${id}`)
  saveAs(blob, `${id}-report.zip`)
}

export const createXlsxExportRequest = (serialNumber: string) => {
  const startTimestamp = addDayIfIsEndDate('from-date-history', false)
  const endTimestamp = addDayIfIsEndDate('to-date-history', true)
  const options = {
    'Content-Type': 'application/json',
    method: 'POST',
    body: JSON.stringify({ start: startTimestamp, end: endTimestamp })
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/excel-export`, undefined, undefined, options)
}

export const checkXlsxExportRequest = (serialNumber: string, exportId: string) => executeRequest(`${devicesEndpoint}/${serialNumber}/excel-export/${exportId}/status`)

export async function getXlsxExportRequest(serialNumber: string, exportId: string) {
  const blob = await executeRequest(`${devicesEndpoint}/${serialNumber}/excel-export/${exportId}/download`)
  saveAs(blob, `${exportId}-excel-report.xlsx`)
}

export const getTerminalCommand = (serialNumber: string, payload: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ ...payload })
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/command`, undefined, undefined, options)
}

export const getDeviceLicenses = (serialNumber: string, state: any) => {
  const params = state
    ? Object.keys(state)
        .filter((e) => state[e])
        .map((e) => 'states=' + e)
        .join('&')
    : ''
  return executeRequest(`${devicesEndpoint}/${serialNumber}/licenses?${params}`)
}

export const updateDeviceLicense = (key, payload) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ ...payload })
  }
  return executeRequest(`${licenseEndpoint}/${key}`, undefined, undefined, options)
}

export const deleteDeviceLicense = (key) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE'
  }

  return executeRequest(`${licenseEndpoint}/${key}`, undefined, undefined, options)
}

export const addDeviceLicense = (serialNumber: string, payload: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'POST',
    body: JSON.stringify({ ...payload })
  }
  return executeRequest(`${devicesEndpoint}/${serialNumber}/custom-license`, undefined, undefined, options)
}

export const submitLicenseKey = (serialNumber: string, licenseKey: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'POST',
    body: licenseKey
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/licenses`, undefined, undefined, options)
}

export const getDeviceDelegations = (serialNumber: string) => {
  return executeRequest(`${devicesEndpoint}/${serialNumber}/delegations`)
}

export const getDeviceCluster = (serialNumber: string) => {
  return executeRequest(`${devicesEndpoint}/${serialNumber}/cluster`)
}

export const getDeviceMultisense = (serialNumber: string) => {
  return executeRequest(`${devicesEndpoint}/${serialNumber}/multi-sense`)
}

export const updateDeviceMultisensePort = (serialNumber: string, portKey: string, payload: AnalogPortForm | TemperaturePortForm) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ ...payload })
  }
  return executeRequest(`${devicesEndpoint}/${serialNumber}/multi-sense/ports/${portKey}`, undefined, undefined, options)
}

export const resetDeviceMultisense = (serialNumber: string, type: 'reset' | 'disable') => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT'
  }

  const apiStr = type === 'reset' ? 'reset' : 'ports/disable'

  return executeRequest(`${devicesEndpoint}/${serialNumber}/multi-sense/${apiStr}`, undefined, undefined, options)
}

export const updatePortInUse = (serialNumber: string, portNr: number) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT'
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/fuel-manager/ports/${portNr}/use`, undefined, undefined, options)
}

export const submitDeviceDelegation = (serialNumber: string, delegation: any) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify([{ ...delegation }])
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/delegations`, undefined, undefined, options)
}

export const removeDeviceDelegation = (serialNumber: string, delegation: any) => {
  const { identity, role } = delegation
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE',
    body: JSON.stringify({ identity, role })
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/delegations`, undefined, undefined, options)
}

export const getDeviceAudits = (serialNumber: string, pageNumber?: number, startDate?: number, endDate?: number) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  const sorting = { sortKey: 'timestamp', sortDirection: 'desc' }

  const startDateQuery = startDate ? `&start=${startDate}` : ''
  const endDateQuery = endDate ? `&end=${endDate}` : ''

  return executeRequest(`${devicesEndpoint}/${serialNumber}/audits?size=100${startDateQuery}${endDateQuery}&page=${pageNumber || 1}`, undefined, sorting, options)
}

//  Batch add or remove delegations for a list of devices (serialNumbers).
//  Provide a role by adding delegations
//  By removing role is opitonal
//  POST: add, DELETE: remove
export const submitBatchDeviceDelegation = (identity: string, deviceSerialNumbers: string[], httpVerb: 'POST' | 'DELETE', role?: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: httpVerb,
    body: JSON.stringify({ identity, ...(role && { role }), deviceSerialNumbers })
  }

  return executeRequest(`${batchEndpoint}/delegations`, undefined, undefined, options)
}

//  Batch add or remove delegations for a list of devices (serialNumbers).
//  Provide a role by adding delegations
//  By removing role is opitonal
//  POST: add, DELETE: remove
export const unlockTransportProtectionLock = (serialNumber: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT'
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/remove-transport-lock`, undefined, undefined, options)
}

export const getDeviceLicense = (key: string) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }
  return executeRequest(`${licenseEndpoint}/${key}`, undefined, undefined, options)
}

export const getGraphMultisense = (serialNumber: string, timeScale: string, startTimestamp: number, endTimestamp: number) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  return executeRequest(
    `${devicesEndpoint}/${serialNumber}/multi-sense/graph?timescale=${timeScale || 'HOURS'}&startTimestamp=${startTimestamp || 0}&endTimestamp=${endTimestamp || 0}`,
    undefined,
    undefined,
    options
  )
}

export const getSelectedDeviceColumns = async () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'GET'
  }

  //  Feature toggle is disabled.
  if (!NEW_API_ACTIVE) {
    return defaultDeviceColumns
  }

  const resp = await executeRequest(`${accountPreferencesEndpoint}/selected-device-columns`, undefined, undefined, options)

  if (resp.status > 200) {
    return defaultDeviceColumns
  }

  return resp
}

export const changeSelectedDeviceColumns = (payload: string[]) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify(payload)
  }
  return executeRequest(`${accountPreferencesEndpoint}/selected-device-columns`, undefined, undefined, options)
}

export const deleteSelectedDeviceColumns = () => {
  const options = {
    'Content-Type': 'application/json',
    method: 'DELETE',
    body: JSON.stringify({})
  }

  return executeRequest(`${accountPreferencesEndpoint}/selected-device-columns`, undefined, undefined, options)
}

export const updateServiceModeRequest = (serialNumber: string, serviceMode: boolean) => {
  const options = {
    'Content-Type': 'application/json',
    method: 'PUT',
    body: JSON.stringify({ serviceMode })
  }

  return executeRequest(`${devicesEndpoint}/${serialNumber}/service-mode`, undefined, undefined, options)
}
