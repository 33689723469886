// @flow

import { Card, CardSection, Col, Gap, LargeCardSection, LargeCardSectionTitle, Row, StatusIndicator } from 'components/ReUsable'
import styled from 'styled-components'
import { productImage, roleLabel } from 'utils/helpers'
import { useCluster, DeviceClusterItem, ClusterTypes, SuccessContent, StatusContent, DenyContent } from './hooks/useCluster'
import { NavLink } from 'react-router-dom'
import { StateText } from '../Overview/detailStateIndicator'
import { getStatusMessage } from 'helpers/status_message'
import { DENIED, DENIED_MISSING_LICENSE, NOT_FOUND } from 'appConstants'

const StyledClusterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @media (min-width: 570px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`

export const StyledRoleInfo = styled.span`
  color: ${({ color }: { color?: string }) => color || 'var(color-text-muted)'};
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`

const StyledImgContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => (props.role === 'controller' ? '0' : '12px')};
  padding-top: ${(props) => (props.role === 'controller' ? '2px' : '0')};
  margin: ${(props) => (props.role === 'controller' ? '0' : '0 6.5px')};
  & > span {
    position: absolute;
    font-family: DigitalSerialBold;
    bottom: -6px;
    right: 2px;
    color: var(--color-primary);
    font-size: 11px;
  }

  & > img {
    width: ${(props) => (props.role === 'controller' ? '35px' : '22px')};
    height: auto;
  }
`

export const StyledCardSection = styled(CardSection)`
  padding: 7px 8px;
  min-height: 126px;
  max-height: 126px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledName = styled.span`
  font-size: 16px;
  color: var(--color-text);
  font-family: DigitalSerialBold;
`

const StyledDeviceImg = styled.img`
  width: 70px;
  height: auto;
`

const StyledStateIndicatorContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: flex-start;
  margin-bottom: 0.2rem;

  & > div {
    position: relative;
  }

  & > span {
    line-height: 1.2rem;
  }
`

const StyledSerialNumber = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 0
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  color: var(--color-text-muted);
`

const StyledInfoCol = styled(Col)`
  min-height: 92px;
  position: relative;
`

const StyledInfoRow = styled(Row)`
  flex-direction: row;
`

const StyledPermissionMissing = styled.span`
  color: var(--color-alert-warning);
  font-family: open-sans-bold;
  line-height: 1.2rem;
`

const deniedStatusValues = [DENIED, DENIED_MISSING_LICENSE, NOT_FOUND]

const statusText = (text: string): string => {
  const lowerCaseText = text.toLowerCase()
  if (lowerCaseText === NOT_FOUND) {
    return 'EFOY not available'
  } else if (lowerCaseText === DENIED) {
    return 'Permission missing'
  } else if (lowerCaseText === DENIED_MISSING_LICENSE) {
    return 'License missing'
  }
  return text
}

const Cluster = ({ serialNumber, role }: { serialNumber: string; role: ClusterTypes }) => {
  const { cluster, loading } = useCluster(serialNumber, role)

  if (!role || role === 'none' || loading) {
    return null
  }
  return (
    <Row margin="1rem 0">
      <Col alignItems="stretch">
        <Card>
          <Row>
            <Col>
              <LargeCardSection>
                <StyledCardHeader>
                  <LargeCardSectionTitle data-cy="cluster-header">Cluster</LargeCardSectionTitle>
                  <StyledImgContainer role={role}>
                    <img data-cy="cluster-icon" src={`/imgs/Role_${role.toLowerCase()}.svg`} alt={roleLabel(role)} />
                    {role === 'controller' ? <span data-cy="cluster-client-counter">{cluster.length}</span> : null}
                  </StyledImgContainer>
                  <StyledRoleInfo data-cy="cluster-role-label">{roleLabel(role)}</StyledRoleInfo>
                </StyledCardHeader>
              </LargeCardSection>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <StyledClusterGrid data-cy="cluster-grid">
                {cluster.map((c: DeviceClusterItem, index: number) => {
                  if (typeof c === 'string' || c === null) {
                    return <></>
                  } else {
                    return <ClusterItem item={c} key={`${c.serialNumber}-${index}`} />
                  }
                })}
              </StyledClusterGrid>
            </Col>
          </Row> */}
        </Card>
      </Col>
    </Row>
  )
}

export const ClusterItem = ({ item }: { item: DeviceClusterItem }) => {
  //  In the first phase of the API implementation, backend will only deliver a string 'none'.
  //  Backend ca
  if (typeof item === 'string' || item === null) {
    return <></>
  }
  const { status, name, role, serialNumber, deviceType } = item
  const isNameAvailable = 'content' in name
  const isStatusAvailable = 'content' in status
  const statusVal = isStatusAvailable ? (status as StatusContent).content?.state : (status as DenyContent).denyReason
  const isConnected = isStatusAvailable ? (status as StatusContent).content?.isConnected : false
  const imgUrl = productImage(deviceType, '_front')
  const itemTemplate: JSX.Element = (
    <StyledCardSection data-cy="cluster-item">
      <>
        <Row>
          <Col>
            <StyledName>{isNameAvailable ? (name as SuccessContent).content : 'Unknown'}</StyledName>
          </Col>
        </Row>
        <StyledInfoRow>
          <Col style={{ flex: 'none' }}>
            <StyledDeviceImg src={imgUrl} alt={deviceType} />
          </Col>
          <Gap width="1rem"></Gap>
          <StyledInfoCol>
            {deniedStatusValues.indexOf(statusVal) === -1 ? (
              <StyledStateIndicatorContainer>
                <StatusIndicator status={statusVal} isConnected={isConnected} />
                <StateText {...{ status: statusVal }}>{`State: ${getStatusMessage(statusVal)}`}</StateText>
              </StyledStateIndicatorContainer>
            ) : (
              <StyledPermissionMissing>{statusText(statusVal)}</StyledPermissionMissing>
            )}
            <StyledRoleInfo color="var(--color-text)">{roleLabel(role)}</StyledRoleInfo>
            <StyledSerialNumber>{serialNumber}</StyledSerialNumber>
          </StyledInfoCol>
        </StyledInfoRow>
      </>
    </StyledCardSection>
  )
  if (!isNameAvailable) {
    return itemTemplate
  } else {
    return (
      <NavLink data-cy="device-detail-link" to={`/devices/${item.serialNumber}`} className="no-style-link" target="_blank">
        {itemTemplate}
      </NavLink>
    )
  }
}

export default Cluster
