import { Auth0Provider } from './utils/react_auth0_spa'
import history from './utils/history'

import App from './App'

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

export default function AuthProvider() {
  const auth0Domain = (window as any).REACT_APP_AUTH0_DOMAIN ? (window as any).REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = (window as any).REACT_APP_AUTH0_CLIENT_ID ? (window as any).REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = (window as any).REACT_APP_AUTH0_AUDIENCE ? (window as any).REACT_APP_AUTH0_AUDIENCE : process.env.REACT_APP_AUTH0_AUDIENCE

  return (
    <Auth0Provider domain={auth0Domain} client_id={clientId} redirect_uri={window.location.origin} onRedirectCallback={onRedirectCallback} audience={audience}>
      <App />
    </Auth0Provider>
  )
}
