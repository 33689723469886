// @flow
import styled from 'styled-components'

export const Card = styled.div`
  padding: 1rem;
  flex: 1 1 1px;
  align-self: stretch;
  justify-self: stretch;
  background: var(--color-background-primary);
`

export const CardSection = styled.section`
  padding: 1rem;
  flex: 1 1 ${(props: any) => props.height || '1px'};
  align-self: stretch;
  justify-self: stretch;
  margin: 0.25rem;
  background: var(--color-background-secondary);
`

export const AnimatedContainer = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: all 0.25s ease-in-out;
  max-height: ${(props: any) => (props.expanded ? '1000px' : '')};
  opacity: ${(props: any) => (props.expanded ? '1' : '0')};
`

interface IAnimatedCardSectionProps {
  expanded?: boolean
  height?: string
  noSpace?: boolean
}

export const AnimatedCardSection = styled(AnimatedContainer)`
  padding: ${(props: IAnimatedCardSectionProps) => (props.expanded ? (props.noSpace ? '0' : '1rem') : '0')};
  margin: ${(props: IAnimatedCardSectionProps) => (props.expanded ? '.25rem' : '0')};
  flex: 1 1 ${(props: IAnimatedCardSectionProps) => props.height || '1px'};
  align-self: stretch;
  justify-self: stretch;
  background: var(--color-background-secondary);
`

export const LargeCardSection = styled.section`
  padding: 1rem;
  flex: 1 1 1px;
  align-self: stretch;
  justify-self: stretch;
  margin: 0.25rem;
  background: var(--color-background-secondary);
`

export const CardSectionTitle = styled.h4`
  padding: 0.8rem 1rem;
  min-height: 58px;
  align-self: stretch;
  justify-self: flex-start;
  background: var(--color-background-secondary);
  margin: 0.25rem;
  font-size: 1.6rem;
  color: var(--color-table-text);
  font-weight: light;
  font-family: DigitalSerialBold;
  align-items: center;

  & > span {
    min-height: 36px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
`

export const LargeCardSectionTitle = styled.section`
  padding: 0rem;
  align-self: stretch;
  justify-self: flex-start;
  background: var(--color-background-secondary);
  margin: 0.1rem;
  font-size: 1.7rem;
  color: var(--color-table-text);
  font-weight: light;
  font-family: DigitalSerialBold;
`

export const DataRow = styled.div`
  margin: 0 0 1.2rem 0;
`

export const DataRowTitle = styled.span`
  display: block;
  color: var(--color-text-light);
  font-family: open-sans-regular;
`

export const DataRowValue = styled.span`
  white-space: nowrap;
  font-family: digital-serial;
  display: block;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-table-text);
`

export const DataRowAdditionalValue = styled.span`
  font-family: digital-serial;
  display: block;
  font-size: 1rem;
  line-height: 1.8rem;
  color: var(--color-additional-value);
`

export const StyledAdminCard = styled(Card)`
  padding: 1rem;
  min-width: 100%;
  position: relative;
  position: relative;
  border: 1px solid var(--color-border-light);
  border-radius: 0.4rem;
  @media (min-width: 991.98px) {
    min-height: 18rem;
  }
`
