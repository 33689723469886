// @flow

import { DEVICE_DETAILS_PERMISSIONS_LIST, DENIED_MISSING_LICENSE, UNSUPPORTED_LICENSE_ERROR } from 'appConstants'
import { NEW_API_ACTIVE } from 'featureToggles'
import styled from 'styled-components'
import { StyledFontAwesomeIcon, StyledImage, StyledDesktopOnlyColumn } from '../DevicesTable/styleDefinitions'
import { isLicenseExpired } from 'utils/helpers'
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'

type Props = {
  values: {
    default: string | number
    hideable: boolean
    permissions: any
    inServiceMode?: boolean
  }
}

const StyledDefaultValue = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &::first-letter {
    text-transform: uppercase;
  }
`

export default function CommonRowCell(props: Props) {
  const defaultValue = props.values.default
  const hideable = props.values.hideable
  const { permissions, inServiceMode } = props.values
  let className = ''

  if (hideable) {
    className = className.concat(' d-none d-lg-table-cell')
  }

  if (isLicenseExpired(permissions) && !NEW_API_ACTIVE) {
    return null
  }

  if (isLicenseExpired(permissions) && NEW_API_ACTIVE) {
    return (
      <StyledDesktopOnlyColumn>
        <StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title="License expired" />
      </StyledDesktopOnlyColumn>
    )
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && !NEW_API_ACTIVE) {
    return null
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && NEW_API_ACTIVE) {
    return (
      <StyledDesktopOnlyColumn>
        <StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title={UNSUPPORTED_LICENSE_ERROR} />
      </StyledDesktopOnlyColumn>
    )
  }

  if (inServiceMode && !NEW_API_ACTIVE) {
    return null
  }

  if (inServiceMode && NEW_API_ACTIVE) {
    return (
      <StyledDesktopOnlyColumn>
        <StyledFontAwesomeIcon icon={faScrewdriverWrench as any} title="In Service" />
      </StyledDesktopOnlyColumn>
    )
  }

  return (
    <td className={className}>
      <StyledDefaultValue>{defaultValue}</StyledDefaultValue>
    </td>
  )
}

export function CommonSimpleRowCell(props: Props) {
  const defaultValue = props.values.default
  const hideable = props.values.hideable
  let className = ''

  if (hideable) {
    className = className.concat(' d-none d-lg-table-cell')
  }

  return (
    <td className={className}>
      <StyledDefaultValue>{defaultValue}</StyledDefaultValue>
    </td>
  )
}

export function LicenseRowCell(props: Props) {
  const defaultValue = props.values.default
  const { permissions } = props.values

  if (isLicenseExpired(permissions) && NEW_API_ACTIVE) {
    return (
      <StyledDesktopOnlyColumn>
        <StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title="License expired" />
      </StyledDesktopOnlyColumn>
    )
  }

  if (permissions[DEVICE_DETAILS_PERMISSIONS_LIST.TELEMETRY_SUMMARY_READ] === DENIED_MISSING_LICENSE && NEW_API_ACTIVE) {
    return (
      <StyledDesktopOnlyColumn>
        <StyledImage alt="License expired" src={'/imgs/upgrade.svg'} title={UNSUPPORTED_LICENSE_ERROR} />
      </StyledDesktopOnlyColumn>
    )
  }

  return (
    <td>
      <StyledDefaultValue>{defaultValue}</StyledDefaultValue>
    </td>
  )
}
